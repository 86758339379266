import React from 'react';

import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';
import ProcessCards from '../components/ProcessCards';

function HowItWorks() {
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <ProcessCards/>
            </div>
            <Footer />
        </div>
    )
}

export default HowItWorks;
import React from 'react';


function Footer() {
    return (
        <div className="new-footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <h2 className="footer-title text-center">Let's get started</h2>
                        <p className="footer-email text-center">hello@joinstack.tech</p>
                        <div className="social-icons text-center">
                            <ul>
                                <li>
                                    <a href="https://facebook.com/joinstack" aria-label="Facebook">
                                        <i className="fa fa-facebook fa-lg" aria-hidden />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/joinstacktech/" aria-label="Instagram">
                                        <i className="fa fa-instagram fa-lg" aria-hidden />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://ro.linkedin.com/company/joinstack" aria-label="Linkedin">
                                        <i className="fa fa-linkedin fa-lg" aria-hidden />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">

                    </div>
                    <div className="col-12 col-md-4">

                    </div>
                </div>
            </div>
          
        </div>
    )
}

export default Footer;